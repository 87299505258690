@import url(https://rsms.me/inter/inter.css);
.style_logo__32Tqk g {
    fill: #deefea;
}

.style_logo__32Tqk {
    width: 50px;
    max-height: 50px;
}

.style_error__2mTbs,
.style_success__QP6Wb {
    color: var(--color-error);
    font-size: 14px;
    border: 2px solid var(--color-error);
    border-radius: var(--border-radius);
    padding: 10px;
    text-align: center;
}

.style_success__QP6Wb {
    color: var(--color-success);
    border: 2px solid var(--color-success);
}

.style_info__1_9nx {
    font-size: 14px;
    text-align: center;
    color: #555;
}

.style_left__xWlHv {
    text-align: left;
}

.style_center__3aOMj {
    text-align: center;
}

.style_right__3m6pG {
    text-align: right;
}

.style_loader__VbPsl {
    color: var(--color-dark);
    opacity: 0.9;
    font-size: 30px;
    text-indent: -9999em;
    overflow: hidden;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    margin: 72px auto;
    position: relative;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: style_load6__2zelE 1.7s infinite ease, style_round__YgJOZ 1.7s infinite ease;
    animation: style_load6__2zelE 1.7s infinite ease, style_round__YgJOZ 1.7s infinite ease;
}

@-webkit-keyframes style_load6__2zelE {
    0% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
            0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    5%,
    95% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
            0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    10%,
    59% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em,
            -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
    }
    20% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em,
            -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
    }
    38% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em,
            -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
    }
    100% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
            0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
}
@keyframes style_load6__2zelE {
    0% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
            0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    5%,
    95% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
            0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    10%,
    59% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em,
            -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
    }
    20% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em,
            -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
    }
    38% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em,
            -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
    }
    100% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
            0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
}
@-webkit-keyframes style_round__YgJOZ {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes style_round__YgJOZ {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

html {
    font-family: 'Inter', sans-serif;
}
@supports (font-variation-settings: normal) {
    html {
        font-family: 'Inter var', sans-serif;
    }
}

* {
    box-sizing: border-box;
}

:root {
    --color-contrast: #2a9d8f;
    --color-font-contrast: #fcfcfc;
    --color-contrast-second: #577590;
    --color-background: #fcfcfc;
    --color-font: #232323;
    --color-dark: #030303;
    --color-success: #43aa8b;
    --color-error: #f94144;
    --border-radius: 10px;
    --margin-basic: 10px;
}

